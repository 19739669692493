.rich-text {
}

.rich-text-text {
  text-wrap: balance;
}

.rich-text-math {
    font-size: 40px;

    .katex {
        //white-space: normal !important;
        white-space: nowrap !important;
    }


}

.rich-text-error {
	color: red;
}
